<script setup lang="ts">
import ReportStatusInfoToastComponent from "@/components/toasts/notifications/reportStatusTypes/InfoToastComponent.vue";
import ReportStatusWarningToastComponent from "@/components/toasts/notifications/reportStatusTypes/WarningToastComponent.vue";
import ReportStatusErrorToastComponent from "@/components/toasts/notifications/reportStatusTypes/ErrorToastComponent.vue";
import ReportStatusSuccessToastComponent from "@/components/toasts/notifications/reportStatusTypes/SuccessToastComponent.vue";
import EventWizardErrorToastComponent from '@/components/toasts/notifications/eventWizardStatusTypes/ErrorToastComponent.vue';
import EventWizardInfoToastComponent from '@/components/toasts/notifications/eventWizardStatusTypes/InfoToastComponent.vue';
import EventWizardSuccessToastComponent from '@/components/toasts/notifications/eventWizardStatusTypes/SuccessToastComponent.vue';
import EventWizardWarningToastComponent from '@/components/toasts/notifications/eventWizardStatusTypes/WarningToastComponent.vue';
import {eventSourceNotification, EventSourceNotificationType} from '@/stores/notifications/eventSourceConnecting'
import { ref, shallowRef, watch } from "vue";

const toasts = ref<any>([]);
const toastTimeout = ref<any>(null);


const deleteToast = (index: number) => {
  toasts.value.splice(index, 1)
}

const registerComponent = (component: object, notification: EventSourceNotificationType) => {
  toasts.value.push({component: shallowRef(component), notification})
  toastTimeout.value = setTimeout(() => {
    toasts.value.shift();
  }, 5000);
}


watch(eventSourceNotification, () => {
  const notification = eventSourceNotification.value;
  if (notification.notificationType === 'report_status') {
    switch (notification.payload?.status?.id) {
      case 0 : return registerComponent(ReportStatusErrorToastComponent, notification)
      case 1 :
      case 2 :
      case 3 : return registerComponent(ReportStatusInfoToastComponent, notification);
      case 4 : return registerComponent(ReportStatusSuccessToastComponent, notification);
      case null : return registerComponent(ReportStatusWarningToastComponent, notification);
    }
  } else if (notification.notificationType === 'event_wizard_status') {
    switch (notification.payload?.status?.id) {
      case 0 :
      case 1 :
      case 10 : return registerComponent(EventWizardInfoToastComponent, notification);
      case 100 : return registerComponent(EventWizardSuccessToastComponent, notification);
      case -1 :
      case -2 : return registerComponent(EventWizardErrorToastComponent, notification);
      case null : return registerComponent(EventWizardWarningToastComponent, notification);
    }
  }



});

</script>

<template>
    <div class="toast-container">
      <TransitionGroup name="toast" tag="div">
        <div v-for="(toast, index) in toasts" :key="index" class="toast">
          <component :is="toast.component" :notification="toast.notification" @delete:toast="deleteToast"/>
        </div>
      </TransitionGroup>
    </div>
</template>

<style scoped lang="scss">

.toast-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 9999;
}

.toast {
  z-index: 9999;
  margin-top: 10px;
}

//.toast-enter-active, .toast-leave-active {
//  transition: all 0.5s ease;
//}
//
//.toast-enter-from,
//.toast-leave-to {
//  opacity: 0;
//  transform: translateX(30px);
//}

.toast-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.toast-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  animation: slide-in 0.5s ease-in-out;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.toast-leave {
  opacity: 0;
  transform: translateX(0);
}

.toast-leave-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  animation: slide-out 0.5s ease-in-out;
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

</style>